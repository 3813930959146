import * as Yup from 'yup';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import moment from 'moment';
// material
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  Stack,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
  Divider,
  Box, Autocomplete,
  Typography,
  OutlinedInput,
  Chip,
  Radio, CircularProgress
} from '@material-ui/core';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//
import { getAnswerSummaryCommentApi, getComplianceByQuestionsApi, getCustomerRefDataTagsApi } from "../../../../_apis_/authApi.js/analytic_api"
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext';
import { AnalyticContext } from '../../../../contexts/AnalyticContext';
import { DateRangeList } from "../../../../utils/filterUtilities";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle: {
    padding: 5
  },
  select: {
    backgroundColor: '#FFFFFF'
  },
  selectDisabled: {
    backgroundColor: '#E9ECEF'
  },
  textField: {
    backgroundColor: '#FFFFFF'
  },
  textFieldLock: {
    backgroundColor: '#E9ECEF'
  },
  selectLock: {
    backgroundColor: '#E9ECEF'
  }
}));

// ----------------------------------------------------------------------

export default function AnalyticsScorecardFilterDialog({
  addUserButton,
  setAddMode,
  closeAddUserDialog,
}) {

  const [searchParams, setSearchParams] = useSearchParams();
  const filterBy = [
    {
      id: 1,
      type: 'Score Date'
    },
    {
      id: 2,
      type: 'Event Date'
    },
    {
      id: 3,
      type: 'Last Edit Date'
    }
  ];

  const formulaType = [
    {
      id: 10,
      type: 'True'
    },
    {
      id: 20,
      type: 'Potential'
    }
  ];


  const {
    getScoreLogGroups,
    reportsScoreLogGroups,
    getScoreLogScorecards,
    scorecardSection,
    getScorecardSection,
    reportsScoreLogScorecards,
    getScoreLogEventType,
    reportsScoreLogEventType,
    getScoreLogEventSubType,
    reportsScoreLogEventSubType,
    getScoreLogTeams,
    reportsScoreLogTeams,
    getScoreLogSupervisor,
    reportsScoreLogSupervisor,
    getScoreLogEmployee,
    reportsScoreLogEmployee,
    getScoreLogCategory,
    getScoreLogEvaluator,

  } = useContext(ScoreCardsContext);
  const {
    subModLabel,
    getQuestionTrend,
    setSelAnaScoreCardFilters,
    selScDuration,
    selectedDateAnaScRange,
    setSelectedDateAnaScRange,
    selectedScFilterBy,
    setselectedScFilter,
    selectedScFilter,
    setSelectedScFilterBy,
    selectedScGroupFilter,
    setSelectedScGroupFilter,
    selectedScTeamFilter,
    setSelectedScTeamFilter,
    selectedScSupervisorFilter,
    setSelectedScSupervisorFilter,
    selectedScFromDate,
    setSelectedScFromDate,
    selectedScToDate,
    setSelectedScToDate,
    selectedScFromWeek,
    setSelectedScFromWeek,
    selectedScToWeek,
    setSelectedScToWeek,
    selectedScSecFilter,
    setSelectedScSecFilter,
    getOverAllTrend,
    selectedScEventType,
    setSelectedScEventType,
    selectedScStaff,
    setSelectedScStaff,
    selectedScSubType,
    setSelectedScSubType,
    setFailAllOnly,
    getSectionTrend,
    getCausesTrend, failAllOnly,
    getCausesByScorecard,
    getCausesByScorecardGraph,
    getEventTypeTrend, selectedFlag,
    timeBtnGroupQueTrend, getDataTagsByScoreCard,
    selectedScFilterByValue,
    setSelectedScFilterByValue,
    setCommentData, setCommentType,
    commentType, getPerformanceAgainstTarget,
    setCommentLoading, dataTagByScoreCard,
    setDataTagByScoreCard,
    getDataTagsByEventData,
    selectedScHighlight, setSelectedScHighlight,
    setComplianceByQuestionData,
    setComplianceByDateType, setselectedDataTagFilter,
    setComplianceByLoading, selectedDataTagFilter,
    setCustomerRefDataTagData, selWeekMonthYearDuration,
    setCustomerRefDataTagLoading,
    selectedScMultipleFilter, setselectedScMultipleFilter,
    selectedScFormula, setSelectedScFormula,
    selectedScNumericValue, setSelectedScNumericValue,
    setNumeric,
  } = useContext(AnalyticContext);

  const { getQuestionsOfSection, scorecardQuestions } = useContext(ScoreCardsContext);

  const [open, setOpen] = useState(false);
  const [circular, setCircular] = useState(false);
  const [advanceFilters, setAdvanceFilters] = useState();
  const [customFilters, setCustomFilters] = useState(false);
  const [fromWeekNo, setFromWeekNo] = useState([]);
  const [toWeekNo, setToWeekNo] = useState([]);
  // Data tags state
  const [isAnyOfTag, setIsAnyOfTag] = useState(true)
  const [isAllOfTag, setIsAllOfTag] = useState(false)

  const isMountedRef = useIsMountedRef();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

  useEffect(() => {
    const groupFilterObj = {
      fromDate: selectedScFromDate ? moment(selectedScFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedScToDate ? moment(selectedScToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedScFilterByValue || "created_at",
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };
    const scoreCardfilterObj = {
      fromDate: selectedScFromDate ? moment(selectedScFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedScToDate ? moment(selectedScToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedScFilterByValue || "created_at",
      group: selectedScGroupFilter || "",
      teams: selectedScTeamFilter || "",
      scoreCard: '',
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };
    getScoreLogGroups(groupFilterObj);
    getScoreLogScorecards(scoreCardfilterObj);
  }, []);

  useEffect(() => {
    if (open) {
      if (selectedDateAnaScRange) {
        setFieldValue('dateRange', selectedDateAnaScRange);
        setFieldValue('fromDate', selectedScFromDate);
        setFieldValue('toDate', selectedScToDate);
      } else {
        setFieldValue('dateRange', 'This Month');
        setFieldValue('fromDate', moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().startOf('month').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('This Month');
      }

      if (selectedDateAnaScRange === 'Custom Date Range') {
        setCustomFilters(true);
        formik.setFieldValue("fromWeek", selectedScFromWeek);
        formik.setFieldValue("toWeek", selectedScToWeek);
      }

      if (selectedScFilterBy) {
        setFieldValue('filterByRange', selectedScFilterBy);
        setFieldValue('filterBy', selectedScFilterByValue);
      } else {
        setFieldValue('filterByRange', 'Score Date');
        setFieldValue('filterBy', 'created_at');
      }

      if (selectedScGroupFilter) {

        setFieldValue('group', selectedScGroupFilter);
      } else {
        setFieldValue('group', '');
      }

      if (selectedScTeamFilter) {
        setFieldValue('teams', selectedScTeamFilter);
      } else {
        setFieldValue('teams', '');
      }

      if (selectedScSupervisorFilter) {
        setFieldValue('supervisor', selectedScSupervisorFilter);
      } else {
        setFieldValue('supervisor', '');
      }

      if (selectedScSecFilter) {
        setFieldValue('section', selectedScSecFilter);
      } else {
        setFieldValue('section', '');
      }


      if (selectedScFilter && subModLabel !== 4.7) {
        setFieldValue("scoreCard", selectedScFilter || (selectedScMultipleFilter?.length > 0 ? selectedScMultipleFilter[0] : ''))
        const filterScoreCardObj = {
          "fromDate": moment(selectedScFromDate).format('YYYY-MM-DD') || searchParams.get('from_date'),
          "toDate": moment(selectedScToDate).format('YYYY-MM-DD') || searchParams.get('to_date'),
          "filterBy": values.filterBy || searchParams.get('filterBy') || 'created_at',
          "scoreCard": selectedScFilter ?? '',
        }
        getDataTagsByScoreCard(filterScoreCardObj);
      } else {
        setFieldValue("scoreCard", '');
      }

      if (selectedScMultipleFilter && subModLabel === 4.7) {
        setFieldValue("scoreCardMultiple", selectedScMultipleFilter || [selectedScFilter])
        const filterScoreCardObj = {
          "fromDate": searchParams.get('fromDate') || moment(values.fromDate).format('YYYY-MM-DD') || searchParams.get('from_date'),
          "toDate": searchParams.get('toDate') || moment(values.toDate).format('YYYY-MM-DD') || searchParams.get('to_date'),
          "filterBy": values.filterBy || searchParams.get('filterBy'),
          "scoreCard": selectedScMultipleFilter ?? '',
        }
        getDataTagsByScoreCard(filterScoreCardObj);
      } else {
        setFieldValue("scoreCardMultiple", []);
      }

      if (selectedScEventType) {
        setFieldValue('eventType', selectedScEventType);
      } else {
        setFieldValue('eventType', '');
      }

      if (selectedScSubType) {
        setFieldValue('eventTypeEventSubType', selectedScSubType);
      } else {
        setFieldValue('eventTypeEventSubType', '');

      }

      if (selectedScStaff) {
        setFieldValue('staff', selectedScStaff)
      } else {
        setFieldValue('staff', '')
      }

      if (selectedScHighlight) {
        setFieldValue('highlightValue', selectedScHighlight);
      } else {
        setFieldValue('highlightValue', '');
      }

      if (selectedDataTagFilter) {
        setFieldValue('dataTag', selectedDataTagFilter);
      } else {
        setFieldValue('dataTag', '');

      }

      if (searchParams.get('dataTagsOperator') !== '20') {
        setIsAnyOfTag(true)
        setIsAllOfTag(false)
        setFieldValue('anyOfTag', '10')
        setFieldValue('allOfTag', '');

      } else {
        setIsAnyOfTag(false)
        setIsAllOfTag(true)

        setFieldValue('anyOfTag', '');
        setFieldValue('allOfTag', '20');
      }

      setFieldValue('staff', '');
      setFieldValue('scoreCardCategory', '');
      setFieldValue('evaluator', '');

      if (selectedScFormula) {
        setFieldValue('formula', selectedScFormula);
      } else {
        setFieldValue('formula', 10);
      }

      if (selectedScNumericValue) {
        setFieldValue('numericValues', selectedScNumericValue);
      } else {
        setFieldValue('numericValues', false);
      }
    }
  }, [open]);
  console.log('circuller', circular)
  const ReportSchema = Yup.object().shape({
    scoreCardMultiple: (subModLabel === 4.7) ? Yup.number().transform((value) => Number.isNaN(value) ? null : value).required('This value is required').nullable() : '',
    scoreCard: (subModLabel !== 4.7) ? Yup.number().transform((value) => Number.isNaN(value) ? null : value).required('This value is required').nullable() : Yup.string(),
    group: (subModLabel === 4.1 || subModLabel === 4.2 || subModLabel === 4.3 || subModLabel === 4.7 ||
      subModLabel === 4.8 || subModLabel === '4.10' || subModLabel === 4.6 || subModLabel === 4.9 ||
      subModLabel === 4.11) ? Yup.string().required('This value is required') : Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      dateRange: '',
      filterByRange: '',
      fromDate: '',
      toDate: '',
      fromWeek: '',
      toWeek: '',
      filterBy: '',
      group: '',
      teams: '',
      scoreCard: '',
      scoreCardMultiple: [],
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: '',
      staff: '',
      scoreCardCategory: '',
      evaluator: '',
      highlightValue: '',
      section: '',
      numericValues: '',
      formula: '',
      scoreCardQuestions: '',
      dataTag: [],
      reference: '',
      allOfTag: '',
      anyOfTag: '',
      // dateRange: '',
    },
    validationSchema: ReportSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      setCircular(true);
      setSelectedScEventType(values?.eventType);
      setSelectedScSubType(values?.eventTypeEventSubType);
      setSelectedScNumericValue(values?.numericValues)
      setNumeric(values?.numericValues)
      try {

        // persist filtered values on refresh
        localStorage.setItem("selectedDateAnaScRange", JSON.stringify(selectedDateAnaScRange || 'This Month'));
        localStorage.setItem("selectedScFilterBy", JSON.stringify(selectedScFilterBy || 'Score Date'));
        localStorage.setItem("selectedScFilterByValue", JSON.stringify(selectedScFilterByValue || "created_at"));
        localStorage.setItem("selectedScFromDate", JSON.stringify(selectedScFromDate));
        localStorage.setItem("selectedScToDate", JSON.stringify(selectedScToDate));
        localStorage.setItem("selectedScGroupFilter", JSON.stringify(selectedScGroupFilter));
        localStorage.setItem("selectedScTeamFilter", JSON.stringify(selectedScTeamFilter));
        localStorage.setItem("selectedScStaff", JSON.stringify(selectedScStaff));
        localStorage.setItem("selectedScSupervisorFilter", JSON.stringify(selectedScSupervisorFilter));
        localStorage.setItem("selectedScFilter", JSON.stringify(selectedScFilter));
        localStorage.setItem("selectedScMultipleFilter", JSON.stringify(selectedScMultipleFilter));
        localStorage.setItem("selectedScEventType", JSON.stringify(selectedScEventType));
        localStorage.setItem("selectedScSubType", JSON.stringify(selectedScSubType));
        // localStorage.setItem("selectedDataTag", JSON.stringify(values?.dataTag))

        if (subModLabel === 4.1) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration === 'quarter' ? 'year' : selScDuration,
            section: values.section || '',
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues || '',
            formula: values.formula || '',
            filterByRange: values.filterByRange,
            reference: values.reference,
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || ''
          };
          setSelAnaScoreCardFilters({ ...filterObj });
          setSearchParams(filterObj)
          localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({ ...filterObj }));
          await getOverAllTrend(filterObj);
          handleClose();
          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false);
          }
        } else if (subModLabel === 4.2) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            filterBy: values.filterBy,
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            // "staff": values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selWeekMonthYearDuration,
            section: values.section || '',
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues || '',
            formula: values.formula || '',
            reference: values.reference,
            filterByRange: values.filterByRange,
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || ''
          };
          // need to do something in the data tag, vlau.data tag
          setSearchParams(filterObj)

          await getEventTypeTrend(filterObj);
          setSelAnaScoreCardFilters({ ...values, scoreCard: values.scoreCard });
          handleClose();
          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false);
          }
        } else if (subModLabel === 4.3) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            // "staff": values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selectedFlag,
            section: values.section,
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues,
            formula: values.formula,
            reference: values.reference,
            filterByRange: values.filterByRange,
          };
          setSearchParams(filterObj)

          await getPerformanceAgainstTarget(filterObj);
          setSelAnaScoreCardFilters(filterObj);

          handleClose();
        } else if (subModLabel === 4.4) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: timeBtnGroupQueTrend === 'quarter' ? 'year' : timeBtnGroupQueTrend,
            section: values.section,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            formula: values.formula,
            reference: values.reference,
            filterByRange: values.filterByRange,
          };
          setSearchParams(filterObj)

          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.length > 0) {
            setSelAnaScoreCardFilters({
              ...filterObj,
              dataTag: values.dataTag || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
            await getQuestionTrend({
              ...filterObj,
              dataTag: values.dataTag || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
            localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({
              ...filterObj,
              dataTag: values.dataTag || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            }));

          } else {
            await getQuestionTrend({
              ...filterObj,
              dataTag: '',
              dataTagsOperator: '10'
            });
            setSelAnaScoreCardFilters({
              ...filterObj,
              dataTag: '',
              dataTagsOperator: '10'
            });
            localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({
              ...filterObj, dataTag: '',
              dataTagsOperator: '10'
            }));
            setselectedDataTagFilter([])
          }

          handleClose();
        } else if (subModLabel === 4.5) {
          // setCircular(true);

          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration,
            section: values.section,
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues,
            reference: values.reference,
            formula: values.formula,
            dataTag: values.dataTag || '',
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || '10'
          };

          setSearchParams(filterObj)

          await getSectionTrend(filterObj);
          setSelAnaScoreCardFilters(filterObj);
          localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({ ...filterObj }));
          handleClose();
        } else if (subModLabel === 4.6) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            dateRange: values.dateRange,
            filterByRange: values.filterByRange,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardMultiple: [values.scoreCard],
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            // "staff": values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration,
            section: values.section,
            supervisor: values.supervisor,
            staff: values.staff,
            scoreCardQuestions: values.scoreCardQuestions,
            numericValues: values.numericValues,
            reference: values.reference,
            formula: values.formula
          };
          setSearchParams(filterObj)

          setSelAnaScoreCardFilters(filterObj);
          try {
            setCommentData([]);
            setCommentLoading(true);
            const { data } = await getAnswerSummaryCommentApi(filterObj, commentType)
            if (data?.data?.length > 0) {
              setCommentData(data);
              setCommentLoading(false);
              setSearchParams({ ...filterObj, scoreCardMultiple: JSON.stringify([values.scoreCard]) })
              handleClose();
            } else {
              setCommentData([]);
              setCommentLoading(false);
              handleClose();
            }
          } catch (err) {
            setCommentData([]);
            setCommentLoading(false);
            handleClose();
          }

        } else if (subModLabel === 4.7) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardMultiple: values.scoreCardMultiple,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            // "staff": values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration,
            section: values.section,
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues,
            reference: values.reference,
            formula: values.formula,
            filterByRange: values.filterByRange,
          };
          setSearchParams(filterObj)

          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.length > 0) {
            filterObj.dataTag = values.dataTag || '';
            filterObj.dataTagsOperator = values.anyOfTag || values.allOfTag || '10';
          } else {
            filterObj.dataTag = '';
            filterObj.dataTagsOperator = '';
          }

          await getCausesByScorecard(filterObj);
          await getCausesByScorecardGraph(filterObj);
          setSelAnaScoreCardFilters(filterObj);
          localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({ ...filterObj }));
          handleClose();
        } else if (subModLabel === 4.8) {

          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            page: '1',
            filterName: '',
            supervisor: values.supervisor,
            staff: values.staff,
            section: values.section,
            scoreCardQuestions: values.scoreCardQuestions,
            flag: selectedFlag,
            numericValues: values.numericValues,
            reference: values.reference,
            dataTag: values.dataTag || '',
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || '10'
          };
          await getCausesTrend(filterObj)
          setSelAnaScoreCardFilters(filterObj);
          localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({ ...filterObj }));

          if (failAllOnly === true) {
            setFailAllOnly(!failAllOnly)
          }
          setSearchParams(filterObj)

          handleClose();
        } else if (subModLabel === 4.9) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            dateRange: values.dateRange,
            filterByRange: values.filterByRange,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardMultiple: [values.scoreCard],
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            // "staff": values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration,
            section: values.section,
            staff: values.staff,
            supervisor: values.supervisor,
            scoreCardQuestions: values.scoreCardQuestions,
            numericValues: values.numericValues,
            formula: values.formula,
            reference: values.reference,
            dataTag: values.dataTag,

          };
          setSelAnaScoreCardFilters(filterObj);
          setSearchParams(filterObj)

          try {
            setComplianceByQuestionData([])
            setComplianceByDateType("month")
            setComplianceByLoading(true);
            const { data } = await getComplianceByQuestionsApi(filterObj, "month")
            if (data?.compliance_data?.length > 0) {
              setComplianceByQuestionData(data);
              setComplianceByLoading(false);
              setSearchParams({ ...filterObj, dataTag: JSON.stringify(values.dataTag), scoreCardMultiple: JSON.stringify([values.scoreCard]) });
              handleClose();
            } else {
              setComplianceByQuestionData([]);
              setComplianceByLoading(false);
              handleClose();
            }
          } catch (err) {
            setComplianceByQuestionData([]);
            setComplianceByLoading(false);
            handleClose();
          }

        } else if (subModLabel === "4.10") {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            section: values.section,
            supervisor: values.supervisor,
            staff: values.staff,
            numericValues: values.numericValues,
            formula: values.formula,
            filterByRange: values.filterByRange,
            dataTags: [values.dataTag],
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || '',
            reference: values.reference
          };
          setSelAnaScoreCardFilters({ ...filterObj, scoreCard: values.scoreCard });
          localStorage.setItem("selAnaScoreCardFilters", JSON.stringify({ ...filterObj }));

          setSearchParams({ ...filterObj });
          await getDataTagsByEventData(filterObj);
          handleClose();
          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false);
          }
        } else if (subModLabel === 4.11) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            dateRange: values.dateRange,
            filterByRange: values.filterByRange,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCard,
            scoreCardMultiple: [values.scoreCard],
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            flag: selScDuration,
            section: values.section,
            staff: values.staff,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            formula: values.formula,
            scoreCardQuestions: values.scoreCardQuestions,
            reference: values.reference,
            dataTag: [values.dataTag],
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || '10',

          };
          setSelAnaScoreCardFilters(filterObj);
          setSearchParams(filterObj)

          try {
            setCustomerRefDataTagData([])
            setCustomerRefDataTagLoading(true);
            const { data } = await getCustomerRefDataTagsApi(filterObj)
            if (data?.customer_references?.length > 0) {
              setCustomerRefDataTagData(data);
              setSearchParams({ ...filterObj, dataTag: [values.dataTag], scoreCardMultiple: JSON.stringify([values.scoreCard]) });
              setCustomerRefDataTagLoading(false);
              handleClose();
            } else {
              setCustomerRefDataTagData([]);
              setCustomerRefDataTagLoading(false);
              handleClose();
            }
          } catch (err) {
            setCustomerRefDataTagData([]);
            setCustomerRefDataTagLoading(false);
            handleClose();
          }

        }

        setCircular(false);

      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
        // setCircular(false);
      }

    },
    enableReinitialze: true
  });


  const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;
  console.log('fromik', formik)
  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close user dialog modal
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);

      if (!setAddMode) {
        closeAddUserDialog(false);
      }
    }
  };

  const handleDateRangeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('group', '');
    setFieldValue('teams', '');
    setFieldValue('scoreCard', '');
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue("scoreCardMultiple", []);
    setselectedScFilter('')
    setselectedScMultipleFilter([])
    setFieldValue('dataTag', []);
    setDataTagByScoreCard([]);

    const dateRange = event.target.value;
    setFieldValue('dateRange', dateRange);
    switch (dateRange) {
      case 'Today':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().format('YYYY-MM-DD'));
        setSelectedScToDate(moment().format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Today');
        break;
      case 'Yesterday':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Yesterday');
        break;
      case 'This Week':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('week').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().startOf('week').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('This Week');
        break;
      case 'Last Week':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Last Week');
        break;
      case 'This Month':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().startOf('month').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('This Month');
        break;
      case 'Last Month':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Last Month');
        break;
      case 'This Quarter':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('This Quarter');
        break;
      case 'Last Quarter':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Last Quarter');
        break;
      case 'This Year':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('year').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().startOf('year').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('This Year');
        break;
      case 'Last Year':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedScFromDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setSelectedScToDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedDateAnaScRange('Last Year');
        break;
      case 'Custom Date Range':
        setCustomFilters(true);
        formik.setFieldValue("fromDate", moment());
        formik.setFieldValue("toDate", moment());

        setSelectedScFromDate(moment());
        setSelectedScToDate(moment());

        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("fromWeek", 1)
          setSelectedScFromWeek(1);
        }
        else {
          setFieldValue("fromWeek", moment().year(moment().format('YYYY')).week());
          setSelectedScFromWeek(moment().year(moment().format('YYYY')).week());
        }
        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("toWeek", 1)
          setSelectedScToWeek(1)
        }
        else {
          setFieldValue("toWeek", moment().year(moment().format('YYYY')).week());
          setSelectedScToWeek(moment().year(moment().format('YYYY')).week());
        }
        setSelectedDateAnaScRange('Custom Date Range');
        break;
      default:
        setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().format('YYYY-MM-DD'));
        break;
    }
  };

  console.log('errors', errors, values, touched)
  useEffect(() => {

    if (open) {
      const arrFromWeek = [];
      const arrToWeek = [];
      if (formik.values.fromWeek > moment().week()) {
        for (let i = 1; i <= formik.values.fromWeek; i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      }

      if (formik.values.toWeek > moment().week()) {
        for (let i = 1; i <= formik.values.toWeek; i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      }
    }



    const filterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: values.group,
      teams: values.teams,
      scoreCard: values.scoreCard,
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const scoreCardfilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: values.group,
      teams: values.teams,
      scoreCard: '',
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const groupFilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: '',
      teams: values.teams,
      scoreCard: values.scoreCard,
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const teamFilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: values.group,
      teams: '',
      scoreCard: values.scoreCard,
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const supFilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: values.group,
      teams: values.teams,
      scoreCard: subModLabel === 4.7 ? values.scoreCardMultiple.toString() : values.scoreCard,
      eventType: values.eventType,
      eventTypeEventSubType: values.eventTypeEventSubType,
      supervisor: '',
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const evntTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": subModLabel === 4.7 ? values.scoreCardMultiple.toString() : values.scoreCard,
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const evntSubTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": subModLabel === 4.7 ? values.scoreCardMultiple.toString() : values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    if (formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {
      getScoreLogGroups(groupFilterObj);
      getScoreLogTeams(teamFilterObj);
      getScoreLogScorecards(scoreCardfilterObj);
      getScoreLogEventType(evntTypeFilterObj);
      getScoreLogEventSubType(evntSubTypeFilterObj);
      getScoreLogSupervisor(supFilterObj);
      getScoreLogEmployee(filterObj);
      getScoreLogCategory(filterObj);
      getScoreLogEvaluator(filterObj);
      getScoreLogEmployee(filterObj);
      if (values.scoreCard) {
        getScorecardSection(values.scoreCard);
      }
    }
  }, [formik?.values]);

  const handleFilterByChange = (event) => {

    // Resetting remaining fields
    setFieldValue('scoreCard', '');
    setFieldValue("scoreCardMultiple", []);

    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('dataTag', []);
    setDataTagByScoreCard([]);

    const filterBy = event.target.value;
    switch (filterBy) {
      case 'Score Date':
        setFieldValue('filterByRange', 'Score Date');
        setFieldValue('filterBy', 'created_at');
        setSelectedScFilterBy('Score Date');
        setSelectedScFilterByValue('created_at')
        break;
      case 'Event Date':
        setFieldValue('filterByRange', 'Event Date');
        setFieldValue('filterBy', 'event_date');
        setSelectedScFilterBy('Event Date');
        setSelectedScFilterByValue('event_date')
        break;
      case 'Last Edit Date':
        setFieldValue('filterByRange', 'Last Edit Date');
        setFieldValue('filterBy', 'updated_at');
        setSelectedScFilterBy('Last Edit Date');
        setSelectedScFilterByValue('updated_at')
        break;
      default:
        setFieldValue('filterByRange', 'Last Edit Date');
        setFieldValue('filterBy', 'updated_at');
        setSelectedScFilterBy('updated_at');
        setSelectedScFilterByValue('updated_at')
        break;
    }

  };

  const handleGroupChange = (event, newVal) => {
    // Resetting remaining fields
    setFieldValue('teams', '');
    setFieldValue('scoreCard', '');
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('dataTag', []);
    setFieldValue("scoreCardMultiple", []);
    setselectedScFilter('')
    setselectedScMultipleFilter([])
    setFieldValue('group', newVal?.id ?? '');
    setDataTagByScoreCard([]);
    setSelectedScGroupFilter(newVal?.id ?? '');

    const filterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: newVal?.id ?? '',
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    getScoreLogTeams(filterObj);
  };

  const handleTeamChange = (event) => {
    // Resetting remaining fields
    setFieldValue('scoreCard', '');
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('dataTag', []);
    // setFieldValue("scoreCardMultiple", []);
    // setselectedScFilter('')
    // setselectedScMultipleFilter([])
    setFieldValue('teams', event.target.value);
    setSelectedScTeamFilter(event.target.value);
    setDataTagByScoreCard([]);
  };

  const handleSupervisorChange = (event) => {
    setFieldValue('supervisor', event.target.value);
    setSelectedScSupervisorFilter(event.target.value);
  };

  const handleSectionChange = (event) => {
    setFieldValue('section', event.target.value);
    setSelectedScSecFilter(event.target.value);
    getQuestionsOfSection(values.scoreCard, event.target.value);
  };

  const handleHighlightChange = (event) => {
    setFieldValue('highlightValue', event.target.value);
    setSelectedScHighlight(event.target.value)
  };


  const handleQuestions = (questionId) => {
    // setUserLev(event.target.value);
  }

  const handleDataTags = (event) => {
    console.log('event', event.target.value)
    setFieldValue("dataTag", event.target.value);
    setselectedDataTagFilter(event.target.value);
    localStorage.setItem('selectedDataTag', JSON.stringify(event.target.value))
  }

  const handleAnyOfTag = (event) => {
    setFieldValue('allOfTag', '')
    setFieldValue('anyOfTag', '10')
    setIsAnyOfTag(true)
    setIsAllOfTag(false)
  }

  const handleAllOfTag = () => {
    setFieldValue('dataTag', [])
    setFieldValue('allOfTag', '20')
    setIsAnyOfTag(false)
    setIsAllOfTag(true)
  }
  console.log('setselectedScMultipleFilter', selectedScMultipleFilter)

  const handleScorecardMultipleChange = (event, newValue) => {
    // Resetting remaining fields
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('dataTag', []);
    setDataTagByScoreCard([]);
    localStorage.setItem('selectedDataTag', JSON.stringify([]))
    setselectedDataTagFilter([])

    const value = [];
    newValue?.map(option => value.push(option?.scoreCard));
    setFieldValue("scoreCardMultiple", value ?? []);
    setFieldValue("scoreCard", value?.length > 0 ? value[0] : '');


    setselectedScMultipleFilter(value ?? []);
    setselectedScFilter(value?.length > 0 ? value[0] : '');

    // if (subModLabel === 4.7) {
    const filterScoreCardObj = {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "scoreCard": value ?? '',
    }
    getDataTagsByScoreCard(filterScoreCardObj)
    // }
  };

  const handleScorecardChange = (event, newValue) => {
    // Resetting remaining fields
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('dataTag', []);

    setDataTagByScoreCard([]);
    localStorage.setItem('selectedDataTag', JSON.stringify([]))
    setselectedDataTagFilter([])
    setFieldValue("scoreCard", newValue?.scoreCard ?? '');
    setFieldValue("scoreCardMultiple", [newValue?.scoreCard]);

    setselectedScFilter(newValue?.scoreCard);
    setselectedScMultipleFilter([newValue?.scoreCard]);

    // if (subModLabel !== 4.7) {
    const filterScoreCardObj = {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "scoreCard": newValue?.scoreCard ?? '',
    }
    getDataTagsByScoreCard(filterScoreCardObj)
    // }
  };

  const handleEventTypeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('eventType', event.target.value);
    setSelectedScEventType(event.target.value);
  };

  const handleSubTypeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');
    setFieldValue('eventTypeEventSubType', event.target.value);
    setSelectedScSubType(event.target.value);
  };

  const handleAdvancedFilters = (event) => {
    setAdvanceFilters((advanceFilters) => !advanceFilters);
  };

  const renderOptions = (props, option) => {
    return <li {...props} key={option?.scoreCard} style={{ color: option?.scoreCardIsArchive ? 'red' : '' }}>
      {option?.scoreCardName}
    </li>
  }

  return (
    <div>
      {addUserButton && (
        <Button variant="contained" onClick={handleClickOpen}>
          Filter
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle sx={{ pb: 2 }}>
              {' '}
              <h3>Analytics Report Filter </h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 15,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: '20px' }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 0, justifyContent: 'end' }}>
                  <Button startIcon={advanceFilters ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    sx={{ p: 0 }}
                    onClick={handleAdvancedFilters}>
                    {advanceFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                  </Button>
                </Stack>
                <Divider />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                      // defaultValue='This Month'
                      labelId="date-range-label"
                      id="demo-select-small"
                      value={values.dateRange}
                      label="Date Range"
                      {...getFieldProps('dateRange')}
                      onChange={(e) => {
                        handleDateRangeChange(e);
                      }}
                    >
                      {DateRangeList?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                          {item.dateRange}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="filter-by-label">Filter By</InputLabel>
                    <Select
                      defaultValue="Score Date"
                      labelId="filter-by-label"
                      id="demo-select-small"
                      value={values.filterByRange}
                      label="Filter By"
                      {...getFieldProps('filterByRange')}
                      onChange={handleFilterByChange}
                    >
                      {filterBy?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.type}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                {customFilters && <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box sx={{ width: "50%" }}>
                      <DesktopDatePicker
                        label="Date From"
                        inputFormat="yyyy/MM/dd"
                        name="fromDate"
                        disableFuture
                        {...getFieldProps('fromDate')}
                        onChange={(newValue) => {
                          setFieldValue("group", '');
                          if (moment(newValue).format('MM-DD') === '01-01') {
                            formik.setFieldValue("fromWeek", 1)
                            setSelectedScFromWeek(1)
                          } else {

                            formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            setSelectedScFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());

                          }
                          setFieldValue("fromDate", newValue);
                          setSelectedScFromDate(newValue)
                          if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                            formik.setFieldValue("toDate", newValue)
                            setSelectedScToDate(newValue);
                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("toWeek", 1)
                              setSelectedScToWeek(1)

                            } else {
                              formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedScToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                    </Box>

                    <Box sx={{ width: "50%" }}>
                      <DesktopDatePicker
                        label="Date To"
                        inputFormat="yyyy/MM/dd"
                        name="toDate"
                        disableFuture
                        {...getFieldProps('toDate')}
                        onChange={(newValue) => {
                          setFieldValue("group", '')

                          if (moment(newValue).format('MM-DD') === '01-01') {
                            formik.setFieldValue("toWeek", 1)
                            setSelectedScToWeek(1)
                          } else {
                            formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            setSelectedScToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                          }
                          setFieldValue("toDate", newValue);
                          setSelectedScToDate(newValue);

                          // Extract year from full date
                          const fullDate = moment(newValue);
                          const extractyear = fullDate.year();

                          if (moment(newValue, "YYYY-MM-DD").isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {
                            formik.setFieldValue("fromDate", moment(newValue).format('YYYY-MM-DD'))
                            setSelectedScFromDate(moment(newValue).format('YYYY-MM-DD'));
                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("fromWeek", 1)
                              setSelectedScFromWeek(1)

                            } else {
                              formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedScFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                          }
                        }}

                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                    </Box>
                  </Stack>
                </LocalizationProvider>}
                {customFilters && <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      required
                      value={formik.values.fromWeek}
                      multiple={false}
                      select
                      name="fromWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("fromWeek", e.target.value);
                        setSelectedScFromWeek(e.target.value);
                        setSelectedScFromDate(moment().week(e.target.value).startOf('week'));
                        formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                      }}

                    >
                      {fromWeekNo?.map((item) => (
                        <MenuItem key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}

                    </TextField>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      multiple={false}
                      value={formik.values.toWeek}
                      required
                      select
                      name="toWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("toWeek", e.target.value);
                        setSelectedScToWeek(e.target.value);
                        setSelectedScToDate(moment().week(e.target.value).endOf('week'));
                        formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                      }}

                    >
                      {toWeekNo?.map((item) => (
                        <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}
                    </TextField>
                  </Box>

                </Stack>}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={(
                        (subModLabel === 4.4 || subModLabel === 4.5)
                          ? ([{
                            name: 'All', id: ''
                          }].concat(reportsScoreLogGroups)) : reportsScoreLogGroups)?.find((option) => option?.id === values?.group)}
                      fullWidth
                      options={(
                        subModLabel === 4.4 ||
                        subModLabel === 4.5
                      ) ? [{
                        name: 'All', id: ''
                      }].concat(reportsScoreLogGroups) : reportsScoreLogGroups ?? []}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, newValue) => {
                        handleGroupChange(event, newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      id="controllable-states-demo"
                      renderInput={(params) => <TextField {...params} label="Group"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        variant="outlined"
                        error={Boolean(touched.group && errors.group)}
                        helperText={touched.group && errors.group}
                        required
                      />}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">
                      Teams
                    </InputLabel>
                    <Select
                      // defaultValue='All'
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.teams}
                      // label="Teams"
                      input={<OutlinedInput notched label="Teams" />}
                      {...getFieldProps('teams')}
                      onChange={handleTeamChange}
                    >
                      <MenuItem value=""> All </MenuItem>
                      {reportsScoreLogTeams?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {(subModLabel === 4.7) && <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={reportsScoreLogScorecards?.filter((option) => {
                        return values?.scoreCardMultiple?.includes(option?.scoreCard)
                      })}
                      fullWidth
                      options={reportsScoreLogScorecards}
                      getOptionLabel={(option) => option?.scoreCardName || ""}
                      renderOption={renderOptions}
                      onChange={(event, newValue) => {
                        handleScorecardMultipleChange(event, newValue);
                      }}

                      id="controllable-states-demo"
                      isOptionEqualToValue={(option, value) => option?.scoreCard === value?.scoreCard}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={typeof option === "string" ? option : option?.scoreCardName}
                            {...getTagProps({ index })}
                          />
                        ));
                      }}
                      renderInput={(params) => <TextField {...params} label="Scorecard"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto',

                          }
                        }}
                        variant="outlined"
                        required
                        // value={selectedScMultipleFilter}
                        name='scoreCardMultiple'
                        {...getFieldProps('scoreCardMultiple')}
                        error={Boolean(touched.scoreCardMultiple && errors.scoreCardMultiple)}
                      // helperText={touched.scoreCardMultiple && errors.scoreCardMultiple}
                      />}
                    />
                    {(touched.scoreCardMultiple && errors.scoreCardMultiple) ?
                      // {(errors.scoreCardMultiple) ?
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>
                        This value is required
                      </Typography>
                      : null}
                  </FormControl>}


                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    {(subModLabel !== 4.7) &&
                      <Autocomplete
                        value={reportsScoreLogScorecards?.find((option) => values?.scoreCard === option?.scoreCard)}
                        fullWidth
                        options={reportsScoreLogScorecards}
                        getOptionLabel={(option) => option?.scoreCardName || ""}
                        renderOption={renderOptions}
                        name='scoreCard'
                        onChange={(event, newValue) => {
                          handleScorecardChange(event, newValue);
                        }}
                        // {...getFieldProps('scoreCard')}
                        id="controllable-states-demo"
                        isOptionEqualToValue={(option, value) => {
                          return option?.scoreCard === value?.scoreCard
                        }}
                        renderTags={(value, getTagProps) => {
                          return value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={typeof option === "string" ? option : option?.scoreCardName}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                        renderInput={(params) => {
                          // console.log('params11', params)
                          return <TextField {...params} label="Scorecard"
                            popoverProps={{
                              style: {
                                bottom: 0,
                                overflowY: 'auto',
                              }
                            }}
                            name='scoreCard'
                            // {...getFieldProps('scoreCard')}
                            variant="outlined"
                            error={(touched.scoreCard && errors.scoreCard && values?.scoreCard === '')}
                            helperText={touched.scoreCard && errors.scoreCard && values?.scoreCard === ''}
                            required
                          />
                        }}
                      />}
                    {formik.errors.scoreCard && formik.touched.scoreCard && values?.scoreCard === '' &&
                      <Typography variant="caption" sx={{ color: "red", ml: 1 }}>
                        This value is required
                      </Typography>}

                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: '0px' }}>
                  {
                    (dataTagByScoreCard?.length > 0) && <> <FormControl fullWidth>
                      <InputLabel shrink id="demo-multiple-name-label">Data Tag(s)</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        // freeSolo
                        value={selectedDataTagFilter !== null ? selectedDataTagFilter : []}
                        name='dataTag'
                        {...getFieldProps('dataTag')}
                        onChange={handleDataTags}
                        input={<OutlinedInput notched label="Data Tag(s)" />}
                      >
                        {dataTagByScoreCard?.map((item, i) => (
                          <MenuItem key={i} id={item.dataTags} value={item.dataTags}>
                            {item.dataTagsName}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    </>
                  }
                </Stack>

                {dataTagByScoreCard?.length > 0 && <Stack direction={{ sm: 'row' }} spacing={0}>
                  <FormControlLabel
                    name="anyOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="10"
                    control={<Radio />}
                    label="Any of the Tags"
                    checked={isAnyOfTag}
                    onChange={handleAnyOfTag}
                  />

                  <FormControlLabel
                    name="allOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="20"
                    control={<Radio />}
                    label="All of the Tags"
                    onChange={handleAllOfTag}
                    checked={isAllOfTag}

                  />
                </Stack>}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="group-label">
                      Event Type
                    </InputLabel>
                    <Select
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      input={<OutlinedInput notched label="Event Type" />}
                      {...getFieldProps('eventType')}
                      onChange={handleEventTypeChange}
                    >
                      <MenuItem value=""> All </MenuItem>
                      {reportsScoreLogEventType?.map((item) => (
                        <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                          {item.eventTypeEventType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">
                      Event Sub Type
                    </InputLabel>
                    <Select
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      input={<OutlinedInput notched label="Event Sub Type" />}
                      {...getFieldProps('eventTypeEventSubType')}
                      onChange={handleSubTypeChange}
                    >
                      <MenuItem value=""> All </MenuItem>
                      {reportsScoreLogEventSubType?.map(
                        (item, index) => <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                          {item.eventTypeEventSubType}
                        </MenuItem>

                      )}
                    </Select>
                  </FormControl>

                </Stack>

                {advanceFilters && (
                  <>
                    {(subModLabel !== 4.9) && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink id="group-label">
                          Supervisor
                        </InputLabel>
                        <Select
                          labelId="group-label"
                          id="demo-select-small"
                          displayEmpty
                          value={values.supervisor}
                          input={<OutlinedInput notched label="Supervisor" />}
                          {...getFieldProps('supervisor')}
                          onChange={handleSupervisorChange}
                        >
                          <MenuItem value=""> All </MenuItem>
                          {reportsScoreLogSupervisor?.map((item) => (
                            <MenuItem key={item.supervisor} id={item.supervisor} value={item.supervisor}>
                              {`${item.supervisorUserFirstName} ${item.supervisorUserLastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>}
                    {
                      (subModLabel === "4.10" || subModLabel === 4.11) && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl fullWidth>
                          <TextField
                            name='Reference'
                            id="outlined-error"
                            label="Reference"
                            variant="outlined"
                            {...getFieldProps('reference')}
                            error={Boolean(touched.reference && errors.reference)}
                            helperText={touched.reference && errors.reference}
                          />
                        </FormControl>

                      </Stack>
                    }

                    {(subModLabel === 4.9) && <FormControl sx={{ width: "100%" }}>
                      <InputLabel shrink id="scorecard-label">Employee</InputLabel>
                      <Select
                        labelId="scorecard-label"
                        id="demo-select-small"
                        displayEmpty
                        input={<OutlinedInput notched label="evaluator" />}
                        error={Boolean(touched.staff && errors.staff)}
                        helperText={touched.staff && errors.staff}
                        value={values.staff}
                        {...getFieldProps('staff')}
                        onChange={(e) => { formik.setFieldValue('staff', e.target.value); setSelectedScStaff(e.target.value) }}
                      >
                        <MenuItem value=""> All </MenuItem>

                        {reportsScoreLogEmployee?.map((item) => (
                          <MenuItem key={item.staff} id={item.staff} value={item.staff}>
                            {`${item.staffUserFirstName} ${item.staffUserLastName}`}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    }

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      {(subModLabel === 4.4 || subModLabel === 4.8 || subModLabel === 4.9) && (
                        <FormControl fullWidth>
                          <InputLabel shrink id="scorecard-label">
                            Sections
                          </InputLabel>
                          <Select
                            defaultValue="All"
                            labelId="scorecard-label"
                            id="demo-select-small"
                            displayEmpty
                            input={<OutlinedInput notched label="Sections" />}
                            value={values.section}
                            {...getFieldProps('section')}
                            onChange={handleSectionChange}
                          >
                            <MenuItem value=""> All </MenuItem>
                            {scorecardSection?.map((item) => (
                              <MenuItem key={item.id} id={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {(subModLabel === 4.8) && (
                        <FormControl fullWidth>
                          <InputLabel id="question-label">Question</InputLabel>
                          <Select
                            defaultValue="All"
                            labelId="question-label"
                            id="demo-select-small"
                            label="Question"
                            {...getFieldProps('scoreCardQuestions')}
                            error={Boolean(touched.scoreCardQuestions && errors.scoreCardQuestions)}
                            helperText={touched.scoreCardQuestions && errors.scoreCardQuestions}
                          >
                            <MenuItem value=""> All </MenuItem>
                            {scorecardQuestions?.map((item) => (
                              <MenuItem onClick={() => { handleQuestions(item.id) }} key={item.id} id={item.id} value={item.id}>
                                {item.question}
                              </MenuItem >
                            ))}
                          </Select>
                          {formik.errors.scoreCardQuestions && formik.touched.scoreCardQuestions ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>{formik.errors.scoreCardQuestions}</Typography> : null}
                        </FormControl>
                      )}
                    </Stack>

                    {(subModLabel === 4.4 || subModLabel === 4.1 || subModLabel === 4.2 || subModLabel === 4.3 || subModLabel === 4.5) && (subModLabel !== "4.10") && (
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        {subModLabel !== 4.8 &&
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              type='number'
                              label="Highlight Below"
                              {...getFieldProps('highlightValue')}
                              error={Boolean(touched.highlightValue && errors.highlightValue)}
                              helperText={touched.highlightValue && errors.highlightValue}
                              onChange={handleHighlightChange}
                            />
                          </FormControl>
                        }
                        {subModLabel !== 4.1 && subModLabel !== 4.2 && subModLabel !== 4.3 &&
                          <FormControl fullWidth>
                            <InputLabel shrink id="group-label">
                              Formula
                            </InputLabel>
                            <Select
                              labelId="group-label"
                              id="demo-select-small"
                              displayEmpty
                              input={<OutlinedInput notched label="Event Type" />}
                              value={values.formula}
                              {...getFieldProps('formula')}
                              onChange={(event) => { setFieldValue('formula', event.target.value); setSelectedScFormula(event.target.value) }}
                            >
                              {formulaType?.map((item) => (
                                <MenuItem key={item.id} id={item.id} value={item.id}>
                                  {item.type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        }
                      </Stack>

                    )}

                    {subModLabel !== 4.1 && subModLabel !== 4.2 && subModLabel !== 4.3 && subModLabel !== "4.10" &&
                      <FormControlLabel
                        label="Numeric Values"
                        control={
                          <Checkbox
                            checked={values.numericValues}
                            {...getFieldProps('numericValues')} size="small" />
                        }
                        sx={{ width: '26%' }}
                      />
                    }

                  </>
                )}
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <Button disabled={circular} type="submit" variant="contained" loading={isSubmitting}>
                Apply
                {circular && <CircularProgress size={14} sx={{ ml: 1 }} />}
              </Button>
              {/* <Button type="submit" variant="contained" loading={isSubmitting}>
                Apply
              </Button> */}
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}